import React,{ useRef } from 'react'
import generatePDF, { Resolution, Margin } from 'react-to-pdf';  
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import Logo from "../asset/dsci-logo-color 1.svg"
import BPMLogo from "../asset/bpmwithtext.png"

const FrameSection = ({cardDetails,setShowCard,croppedImage,setCardDetails,setCroppedImage}) => {
 console.log(cardDetails)
 const componentRef = useRef();

 
  return (
    <div className='lg:pt-[8px] pt-12 px-2 lg:px-6'>
      <div ref={componentRef} id="content-id" className='frameSection border-[1px] border-gray-100  w-[350px] h-[380px] lg:w-[600px] lg:h-[600px] relative' >
       
         <div className=''>
            <h6 className='absolute  text-[.9rem] lg:text-[1.7rem] font-[600] top-[17%] lg:top-[16.5%] right-[7%] lg:right-[8%]' >{cardDetails.type}</h6>
            
            <div className='flex-col absolute w-[120px] top-[29%] lg:top-[29%] right-[2%] lg:right-[5.3%]  flex items-center justify-center lg:w-[200px]  ' >
            <img className=' w-[115px] px-1 pt-2 lg:px-2 lg:py-2 h-[120px] lg:h-[205px] lg:w-[195px]' src={croppedImage} />
            <h3 className='lg:text-[1rem] lg:w-[200px] w-[120px] mt-8 pl-3 capitalize text-[.5rem] font-[600] '>{cardDetails.name}</h3>
            <h3 className='lg:text-[.9rem] lg:w-[200px] lg:max-w-[200px] w-[120px] pl-3 text-[.4rem]'>{cardDetails.designation}</h3>
            </div>
         </div>
      </div>
        
        <div className='flex mt-4 lg:pl-2 items-center justify-start gap-6'>
            <button onClick={()=>{
               setCardDetails({
                  name:"",
                  designation:"",
                  type:"",
               })
               setCroppedImage("")
               setShowCard(false)}} style={{width:"200px"}} className='bg-gray-400 uppercase w-[150px]  gap-3 rounded-lg shadow-md  ease-in duration-300  text-[10px] md:text-[16px]  hover:shadow-none hover:bg-[#272727] tracking-wider py-[8px] px-[12px]  md:py-[12px] md:px-[24px] text-[#272727] '>Start Again</button>
            <button style={{width:"200px"}} onClick={() => exportComponentAsPNG(componentRef)} className="uppercase w-[150px]  flex  items-center justify-center gap-3 rounded-lg shadow-md  ease-in duration-300  text-[12px] md:text-[16px]  hover:shadow-none hover:bg-[#272727] tracking-wider py-[8px] px-[24px] md:py-[12px] md:px-[24px] text-[#272727] bg-gradient-to-r from-[#1cccf2] to-[#25e6f9]">Download</button>
        </div>
    </div>
  )
}

export default FrameSection

import './App.css';
import React, { useState } from 'react'
import FrameSection from './component/FrameSection'
import FormSection from './component/FormSection'
import Hyperloop from "./asset/earth.png"
import BPMlogo from "./asset/AISS White Logo.png"
function App() {
  const [showCard, setShowCard] = useState(false)
    const [cardDetails, setCardDetails] = useState({
        name:"",
        designation:"",
        type:"",
      
    })
    const [croppedImage, setCroppedImage] = useState(null);
  return (
    <div className='mainPhotobooth'>
    <div className='md:grid items-center md:gap-12 justify-center grid-cols-2 '>
      <div>
    {
        showCard ? <FrameSection croppedImage={croppedImage} setCroppedImage={setCroppedImage} cardDetails={cardDetails} setCardDetails={setCardDetails} showCard={showCard} setShowCard={setShowCard} /> : <FormSection croppedImage={croppedImage} setCroppedImage={setCroppedImage}   cardDetails={cardDetails} setCardDetails={setCardDetails} showCard={showCard} setShowCard={setShowCard} />
    }
    </div> 
    <div className='lg:flex hidden flex-col items-center mt-12  justify-center'>
      <img className='w-[200px]' src={BPMlogo} />
      <img className='lg:block hidden   ' src={Hyperloop} />
    </div>
    <div>

    </div>
    </div>
</div>
  );
}

export default App;
